@import '../../shared/utils/theme/color.scss';

.login-wrapper {
	width: 328px;
	min-height: 360px;
	border-radius: 5px;
	background-blend-mode: multiply;
	background-color: rgba(211, 227, 239, 0.6);

	.login-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 3rem;
		.login-input {
			width: 23.7rem;
		}
		.login-form-button {
			width: 237px;
			height: 44px;
			border-radius: 3px;
			box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
			background-image: linear-gradient(to bottom, #009f00, #058405);
			margin-top: 1.5rem;
			.login-form-button-text {
				font-family: 'Roboto';
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.4px;
				text-align: center;
				color: $white;
			}
		}
	}
	.login-form-footer {
		padding-left: 4.5rem;
		padding-top: 2.9rem;
		.login-form-footer-text-1 {
			font-family: 'Roboto';
			font-size: 14px;
			line-height: 1.43;
			color: $paleSky;
		}
		.login-form-footer-text-2 {
			width: 144px;
			height: 120px;
			font-family: 'Roboto';
			font-size: 14px;
			line-height: 1.43;
			color: $paleSky;
		}
	}
	.registered-user-button {
		cursor: pointer;
	}
	.ant-form-item {
		margin-bottom: 5px;
	}
}
