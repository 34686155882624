/* @import '~antd/dist/antd.css'; */
@import '../src//shared/utils/theme/color.scss';
html,
body,
#root {
	height: 100%;
	font-size: 10px;
	font-family: 'OpenSans', 'Roboto';
	// background-color: $athensGray;
	// background-color: #e8f1f7;
	user-select: none;
}
* {
	box-sizing: border-box;
	padding: 0;
}
