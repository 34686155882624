//Color Pallette

$white: #ffffff;
$black: #000000;

$steelBlue: #5c79b5;
$congressBlue: #004080;

$athensGray: #f4f6f8;
$paleSky: #637381;
$ebonyClay: #212b36;
$porcelain: #eff0f1;
$riverBed: #454f5c;
$midNight: #021c39;
$riverBed-2: #475663;
$doveGray: #6e6e6e;
$paleSky-2: #627382;
$alto: #d4d2d2;
$redOrange: #fc2b2b;
$japaneseLaurel: #00a100;
$regentGray: #939ba0;
$doublePearlLusta: #fcf1cd;
$mystic: #e5ebed;
$apple: #5fbc3f;
$ebb: #f0ebeb;
$dustyGray: #979797;
$paleGray: #e8f1f7;
