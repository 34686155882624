@import '../../utils//theme/color.scss';

.faq-section-container {
	width: 359px;
	.faq-title {
		font-family: 'Libre Baskerville';
		font-size: 25px;
		font-weight: bold;
		letter-spacing: -1.3px;
		color: #454f5c;
	}
	.faq-items {
		padding-top: 1.4rem;
		.faq-question {
			font-family: 'Open Sans';
			font-size: 16px;
			font-weight: 600;
			line-height: 1.25;
			letter-spacing: -0.8px;
			color: $riverBed;
		}
		.faq-answer {
			font-family: 'Open Sans';
			font-size: 16px;
			line-height: 1.25;
			letter-spacing: -0.8px;
			color: $riverBed;
		}
	}
}
