/* @import '~antd/dist/antd.css'; */
html,
body,
#root {
  height: 100%;
  font-size: 10px;
  font-family: 'OpenSans', 'Roboto';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

* {
  box-sizing: border-box;
  padding: 0; }

.landing-page-wrapper {
  background-color: #e9f1f6;
  height: 100%; }
  .landing-page-wrapper .landing-page-first-section {
    background-image: url(/static/media/landingpage.1d8c641f.png);
    background-repeat: no-repeat;
    height: 562px;
    background-size: 100% 100%; }
    .landing-page-wrapper .landing-page-first-section .landing-page-logo {
      padding-left: 29rem; }
    .landing-page-wrapper .landing-page-first-section .landing-page-text {
      width: 753px;
      font-family: 'Libre Baskerville';
      font-size: 46px;
      font-weight: bold;
      letter-spacing: -2.3px;
      color: #ffffff;
      padding-top: 25rem;
      padding-left: 29rem; }
  .landing-page-wrapper .landing-page-second-section {
    padding-bottom: 5rem;
    background-color: #e9f1f6;
    display: -webkit-flex;
    display: flex;
    padding-left: 11rem;
    padding-right: 11rem;
    -webkit-justify-content: space-around;
            justify-content: space-around; }
    .landing-page-wrapper .landing-page-second-section .landing-page-info {
      padding-top: 2rem; }
      .landing-page-wrapper .landing-page-second-section .landing-page-info .landing-page-info-text-1 {
        width: 359px;
        font-family: 'Open Sans';
        font-size: 21px;
        line-height: 1.52;
        letter-spacing: -1.1px;
        color: #454f5c; }
    .landing-page-wrapper .landing-page-second-section .landingpage-faq {
      padding-top: 2.4rem; }
  .landing-page-wrapper .landing-page-login {
    padding-top: 3rem;
    padding-left: 10.5rem; }

.login-wrapper {
  width: 328px;
  min-height: 360px;
  border-radius: 5px;
  background-blend-mode: multiply;
  background-color: rgba(211, 227, 239, 0.6); }
  .login-wrapper .login-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 3rem; }
    .login-wrapper .login-form .login-input {
      width: 23.7rem; }
    .login-wrapper .login-form .login-form-button {
      width: 237px;
      height: 44px;
      border-radius: 3px;
      box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
      background-image: -webkit-linear-gradient(top, #009f00, #058405);
      background-image: linear-gradient(to bottom, #009f00, #058405);
      margin-top: 1.5rem; }
      .login-wrapper .login-form .login-form-button .login-form-button-text {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.4px;
        text-align: center;
        color: #ffffff; }
  .login-wrapper .login-form-footer {
    padding-left: 4.5rem;
    padding-top: 2.9rem; }
    .login-wrapper .login-form-footer .login-form-footer-text-1 {
      font-family: 'Roboto';
      font-size: 14px;
      line-height: 1.43;
      color: #637381; }
    .login-wrapper .login-form-footer .login-form-footer-text-2 {
      width: 144px;
      height: 120px;
      font-family: 'Roboto';
      font-size: 14px;
      line-height: 1.43;
      color: #637381; }
  .login-wrapper .registered-user-button {
    cursor: pointer; }
  .login-wrapper .ant-form-item {
    margin-bottom: 5px; }

.faq-section-container {
  width: 359px; }
  .faq-section-container .faq-title {
    font-family: 'Libre Baskerville';
    font-size: 25px;
    font-weight: bold;
    letter-spacing: -1.3px;
    color: #454f5c; }
  .faq-section-container .faq-items {
    padding-top: 1.4rem; }
    .faq-section-container .faq-items .faq-question {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.8px;
      color: #454f5c; }
    .faq-section-container .faq-items .faq-answer {
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: -0.8px;
      color: #454f5c; }

.signin-wrapper {
  width: 328px;
  min-height: 360px;
  border-radius: 5px;
  background-blend-mode: multiply;
  background-color: rgba(211, 227, 239, 0.6); }
  .signin-wrapper .signin-title {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #212b36; }
  .signin-wrapper .signin-img {
    width: 2rem;
    height: 2rem; }
  .signin-wrapper .login-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem; }
    .signin-wrapper .login-form .login-input {
      width: 23.7rem; }
    .signin-wrapper .login-form .signin-form-button {
      width: 237px;
      height: 44px;
      opacity: 0.8;
      border-radius: 3px;
      box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06);
      border: solid 1px #4f5dba;
      background-color: #000080;
      margin-top: 1.5rem; }
      .signin-wrapper .login-form .signin-form-button .login-form-button-text {
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.4px;
        text-align: center;
        color: #ffffff; }
  .signin-wrapper .login-form-footer {
    padding-left: 4.5rem;
    padding-top: 2.9rem; }
    .signin-wrapper .login-form-footer .login-form-footer-text-1 {
      font-family: 'Roboto';
      font-size: 14px;
      line-height: 1.43;
      color: #637381; }
    .signin-wrapper .login-form-footer .login-form-footer-text-2 {
      width: 144px;
      height: 120px;
      font-family: 'Roboto';
      font-size: 14px;
      line-height: 1.43;
      color: #637381; }
  .signin-wrapper .registered-user-button {
    cursor: pointer; }
  .signin-wrapper .ant-form-item {
    margin-bottom: 5px; }

.bizz-landing-wrapper {
  width: 100%;
  height: 100vh; }
  .bizz-landing-wrapper .bizz-section1 {
    background-image: url(/static/media/BG.b2dc47a1.png);
    background-size: 100% 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .bizz-landing-wrapper .bizz-section1 .logo-wrapper {
      float: left; }
    .bizz-landing-wrapper .bizz-section1 .section1-content {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-around;
              justify-content: space-around; }
      .bizz-landing-wrapper .bizz-section1 .section1-content .section1-left {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        margin-top: 8rem; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .section1-left .section1-text {
          color: #f0eaea;
          font-family: serif;
          font-family: initial;
          font-size: 3rem;
          text-align: center; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .section1-left .section1-image {
          width: 40rem;
          height: 40rem; }
      .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper {
        background-color: #ffffff;
        border-radius: 2rem;
        padding: 2rem;
        margin-top: -2rem;
        margin-bottom: 14rem;
        width: 40%;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-badge {
          margin-top: -2rem;
          margin-right: -2rem; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-wrapper-step1 {
          padding: 1rem;
          width: 100%; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-wrapper-step2 {
          padding: 1rem;
          width: 100%; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-wrapper-step3 {
          padding: 1rem;
          width: 100%; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .button-section {
          margin-top: 1rem;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .ant-form-item-required.ant-form-item-no-colon {
          white-space: normal;
          white-space: initial; }
  .bizz-landing-wrapper .bizz-section2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .bizz-landing-wrapper .bizz-section2 .section2-heading {
      margin-top: 3rem;
      line-height: 3rem;
      font-family: serif;
      font-size: 3.5rem;
      margin-left: 15rem; }
    .bizz-landing-wrapper .bizz-section2 .section2-cards {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 4rem;
        width: 30%;
        box-shadow: 0 13px 30px 0 rgba(0, 0, 0, 0.08); }
        .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text .card-image {
          height: 7rem; }
        .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text .card-heading {
          font-weight: bold;
          line-height: 1rem;
          padding: 1rem; }
        .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text .card-content {
          line-height: 2rem;
          font-family: sans-serif;
          font-size: 1.5rem;
          padding: 1rem; }
  .bizz-landing-wrapper .ant-form-explain {
    color: red;
    font-size: 1.2rem; }
  .bizz-landing-wrapper .ant-form-item-label {
    text-align: left; }
  .bizz-landing-wrapper .ant-row.ant-form-item.label {
    margin-bottom: 10px; }
  .bizz-landing-wrapper .ant-badge.ant-badge-not-a-wrapper {
    float: right;
    margin-top: -0.5rem; }
  .bizz-landing-wrapper .ant-form-item-no-colon {
    font-size: 12px; }
  .bizz-landing-wrapper .ant-checkbox-inner {
    border-color: #0b209b; }

@media only screen and (max-width: 900px) {
  .bizz-landing-wrapper .bizz-section1 {
    background-image: url(/static/media/BG.b2dc47a1.png);
    background-size: 100% 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .bizz-landing-wrapper .bizz-section1 .logo-wrapper {
      float: left; }
    .bizz-landing-wrapper .bizz-section1 .section1-content {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-top: -5rem; }
      .bizz-landing-wrapper .bizz-section1 .section1-content .section1-left {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .section1-left .section1-text {
          color: #f0eaea;
          font-family: serif;
          font-family: initial;
          font-size: 3rem;
          margin: 3rem;
          text-align: center; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .section1-left .section1-image {
          display: none; }
      .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper {
        background-color: #ffffff;
        border-radius: 2rem;
        padding: 2rem;
        width: 95%;
        margin-right: 3rem;
        margin-left: 3rem; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-badge {
          margin-top: -2rem;
          margin-right: -2rem; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-wrapper-step1 {
          padding: 1rem;
          width: 100%; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-wrapper-step2 {
          padding: 1rem;
          width: 100%; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .card-wrapper-step3 {
          padding: 1rem;
          width: 100%; }
        .bizz-landing-wrapper .bizz-section1 .section1-content .card-wrapper .button-section {
          margin: 1rem;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between; }
  .bizz-landing-wrapper .bizz-section2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .bizz-landing-wrapper .bizz-section2 .section2-heading {
      margin-left: 0px; }
    .bizz-landing-wrapper .bizz-section2 .section2-cards {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        margin: 2rem;
        width: 50%;
        box-shadow: 0 13px 30px 0 rgba(0, 0, 0, 0.08); }
        .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text .card-image {
          height: 7rem; }
        .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text .card-heading {
          font-weight: bold;
          line-height: 1.5rem;
          padding: 1rem; }
        .bizz-landing-wrapper .bizz-section2 .section2-cards .section2-text .card-content {
          line-height: 2rem;
          font-family: sans-serif;
          font-size: 1.5rem;
          padding: 1rem; }
  .bizz-landing-wrapper .ant-form-explain {
    color: red; }
  .bizz-landing-wrapper .ant-form-item-label {
    text-align: left; }
  .bizz-landing-wrapper .ant-row.ant-form-item.label {
    margin-bottom: 10px; }
  .bizz-landing-wrapper .ant-badge.ant-badge-not-a-wrapper {
    float: right;
    margin-top: -0.5rem; }
  .bizz-landing-wrapper .ant-form-item-no-colon {
    font-size: 12px; }
  .ant-checkbox-inner {
    border-color: #0b209b; } }

.biz-footer-wrapper {
  background: #283c86;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #45a247, #283c86);
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(left, #45a247, #283c86);
  background: linear-gradient(to right, #45a247, #283c86);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  font-weight: normal;
  font-weight: initial;
  display: -webkit-flex;
  display: flex;
  width: 100%; }
  .biz-footer-wrapper .address {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: left;
    font-size: 2rem;
    font-family: sans-serif;
    margin: 3rem; }
  .biz-footer-wrapper .content {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: left;
            align-items: left; }
    .biz-footer-wrapper .content .biz-text {
      font-size: 1.8rem;
      font-family: sans-serif; }
    .biz-footer-wrapper .content .biz-city {
      font-size: 1.8rem;
      font-family: sans-serif; }
  .biz-footer-wrapper .biz-logo {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }

@media only screen and (max-width: 900px) {
  .biz-footer-wrapper {
    background: #283c86;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #45a247, #283c86);
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(left, #45a247, #283c86);
    background: linear-gradient(to right, #45a247, #283c86);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    font-weight: normal;
    font-weight: initial;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .biz-footer-wrapper .address {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      text-align: left;
      font-size: 1.3rem;
      font-family: sans-serif;
      margin: 3rem; }
    .biz-footer-wrapper .content {
      -webkit-flex: 1 1;
              flex: 1 1;
      padding: 3rem;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      text-align: left; }
      .biz-footer-wrapper .content .biz-text {
        text-align: left;
        font-size: 1.2rem;
        font-family: sans-serif; }
      .biz-footer-wrapper .content .biz-city {
        text-align: left;
        font-size: 1.2rem;
        font-family: sans-serif; }
    .biz-footer-wrapper .biz-logo {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 4rem; } }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

