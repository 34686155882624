.biz-footer-wrapper {
	background: #283c86; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #45a247, #283c86); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#45a247,
		#283c86
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	color: white;
	font-weight: initial;
	display: flex;
	width: 100%;

	.address {
		flex: 1 1 0;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
		font-size: 2rem;
		font-family: sans-serif;
		margin: 3rem;
	}
	.content {
		flex: 1 1 0;
		padding: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: left;
		.biz-text {
			font-size: 1.8rem;
			font-family: sans-serif;
		}
		.biz-city {
			font-size: 1.8rem;
			font-family: sans-serif;
		}
	}
	.biz-logo {
		flex: 1 1 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (max-width: 900px) {
	.biz-footer-wrapper {
		background: #283c86; /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #45a247, #283c86); /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(
			to right,
			#45a247,
			#283c86
		); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

		color: white;
		font-weight: initial;
		display: flex;
		width: 100%;
		flex-direction: column;
		.address {
			flex: 1 1 0;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: left;
			font-size: 1.3rem;
			font-family: sans-serif;
			margin: 3rem;
		}
		.content {
			flex: 1 1 0;
			padding: 3rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: left;
			.biz-text {
				text-align: left;
				font-size: 1.2rem;
				font-family: sans-serif;
			}
			.biz-city {
				text-align: left;
				font-size: 1.2rem;
				font-family: sans-serif;
			}
		}
		.biz-logo {
			flex: 1 1 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 4rem;
		}
	}
}
