@import '../../shared//utils/theme/color.scss';

.landing-page-wrapper {
	background-color: #e9f1f6;
	height: 100%;
	.landing-page-first-section {
		background-image: url('../../shared/utils/images/landingpage.png');
		background-repeat: no-repeat;
		height: 562px;
		background-size: 100% 100%;
		.landing-page-logo {
			padding-left: 29rem;
		}
		.landing-page-text {
			width: 753px;
			font-family: 'Libre Baskerville';
			font-size: 46px;
			font-weight: bold;
			letter-spacing: -2.3px;
			color: $white;
			padding-top: 25rem;
			padding-left: 29rem;
		}
	}
	.landing-page-second-section {
		padding-bottom: 5rem;
		background-color: #e9f1f6;
		display: flex;
		padding-left: 11rem;
		padding-right: 11rem;
		justify-content: space-around;
		.landing-page-info {
			padding-top: 2rem;
			.landing-page-info-text-1 {
				width: 359px;
				font-family: 'Open Sans';
				font-size: 21px;
				line-height: 1.52;
				letter-spacing: -1.1px;
				color: $riverBed;
			}
		}
		.landingpage-faq {
			padding-top: 2.4rem;
		}
	}
	.landing-page-login {
		padding-top: 3rem;
		padding-left: 10.5rem;
	}
}
