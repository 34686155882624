$bg-clr: url('../../utils/images/BG.png');

.bizz-landing-wrapper {
	width: 100%;
	height: 100vh;
	.bizz-section1 {
		background-image: $bg-clr;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		.logo-wrapper {
			float: left;
		}
		.section1-content {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			.section1-left {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-top: 8rem;
				.section1-text {
					color: rgb(240, 234, 234);
					font-family: initial;
					font-size: 3rem;
					text-align: center;
				}
				.section1-image {
					width: 40rem;
					height: 40rem;
				}
			}
			.card-wrapper {
				background-color: #ffffff;
				border-radius: 2rem;
				padding: 2rem;
				margin-top: -2rem;
				margin-bottom: 14rem;
				width: 40%;
				box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
				.card-badge {
					margin-top: -2rem;
					margin-right: -2rem;
				}
				.card-wrapper-step1 {
					padding: 1rem;
					width: 100%;
				}
				.card-wrapper-step2 {
					padding: 1rem;
					width: 100%;
				}
				.card-wrapper-step3 {
					padding: 1rem;
					width: 100%;
				}
				.button-section {
					margin-top: 1rem;
					display: flex;
					justify-content: space-between;
				}
				.ant-form-item-required.ant-form-item-no-colon {
					white-space: initial;
				}
			}
		}
	}

	.bizz-section2 {
		display: flex;
		flex-direction: column;
		.section2-heading {
			margin-top: 3rem;
			line-height: 3rem;
			font-family: serif;
			font-size: 3.5rem;
			margin-left: 15rem;
		}
		.section2-cards {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			.section2-text {
				display: flex;
				flex-direction: column;
				margin: 4rem;
				width: 30%;
				box-shadow: 0 13px 30px 0 rgba(0, 0, 0, 0.08);
				.card-image {
					height: 7rem;
				}
				.card-heading {
					font-weight: bold;
					line-height: 1rem;
					padding: 1rem;
				}
				.card-content {
					line-height: 2rem;
					font-family: sans-serif;
					font-size: 1.5rem;
					padding: 1rem;
				}
			}
		}
	}

	.ant-form-explain {
		color: red;
		font-size: 1.2rem;
	}
	.ant-form-item-label {
		text-align: left;
	}
	.ant-row.ant-form-item.label {
		margin-bottom: 10px;
	}
	.ant-badge.ant-badge-not-a-wrapper {
		float: right;
		margin-top: -0.5rem;
	}
	.ant-form-item-no-colon {
		font-size: 12px;
	}
	.ant-checkbox-inner {
		border-color: rgb(11, 32, 155);
	}
}

@media only screen and (max-width: 900px) {
	.bizz-landing-wrapper {
		.bizz-section1 {
			background-image: $bg-clr;
			background-size: 100% 100%;
			display: flex;
			flex-direction: column;
			.logo-wrapper {
				float: left;
			}
			.section1-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-top: -5rem;
				.section1-left {
					display: flex;
					justify-content: center;
					align-items: center;
					.section1-text {
						color: rgb(240, 234, 234);
						font-family: initial;
						font-size: 3rem;
						margin: 3rem;
						text-align: center;
					}
					.section1-image {
						display: none;
					}
				}
				.card-wrapper {
					background-color: #ffffff;
					border-radius: 2rem;
					padding: 2rem;
					width: 95%;
					margin-right: 3rem;
					margin-left: 3rem;
					.card-badge {
						margin-top: -2rem;
						margin-right: -2rem;
					}
					.card-wrapper-step1 {
						padding: 1rem;
						width: 100%;
					}
					.card-wrapper-step2 {
						padding: 1rem;
						width: 100%;
					}
					.card-wrapper-step3 {
						padding: 1rem;
						width: 100%;
					}
					.button-section {
						margin: 1rem;
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}

		.bizz-section2 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.section2-heading {
				margin-left: 0px;
			}
			.section2-cards {
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				.section2-text {
					display: flex;
					flex-direction: column;
					margin: 2rem;
					width: 50%;
					box-shadow: 0 13px 30px 0 rgba(0, 0, 0, 0.08);
					.card-image {
						height: 7rem;
					}
					.card-heading {
						font-weight: bold;
						line-height: 1.5rem;
						padding: 1rem;
					}
					.card-content {
						line-height: 2rem;
						font-family: sans-serif;
						font-size: 1.5rem;
						padding: 1rem;
					}
				}
			}
		}
		.ant-form-explain {
			color: red;
		}
		.ant-form-item-label {
			text-align: left;
		}
		.ant-row.ant-form-item.label {
			margin-bottom: 10px;
		}
		.ant-badge.ant-badge-not-a-wrapper {
			float: right;
			margin-top: -0.5rem;
		}
		.ant-form-item-no-colon {
			font-size: 12px;
		}
	}
	.ant-checkbox-inner {
		border-color: rgb(11, 32, 155);
	}
}
